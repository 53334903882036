.servis__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.servis {
    background: var(--color-bg-varient);
    border-radius: 0 0 2rem 2rem;
    border : 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}
.servis:hover {
    background: transparent;
    border-color: var(--color-primary);
    color: default;
}

.servis__head {
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.servis__head h3 {
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
}

.servis__list {
    padding: 2rem;
}
.servis__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}
.servis__list-icon {
    color: var(--color-primary);
    margin-top: 2px;
}
.servis__list-p {
    font-size: 0.9rem;
}

/* ******** Media Queries (MEDIUM DEVICES) ********* */


@media screen and (max-width: 1024px){

    .servis__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    .servis {
        height: auto;
    }
    
  }
  
  /* ******** Media Queries (SMALL DEVICES) ********* */
  
  
  @media screen and (max-width: 1024px){
  
    .servis__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
    
  }